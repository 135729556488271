import { AxiosResponse } from "axios";
import mainApi from "../../config/mainApi";

export const createPaymentHistory = async (data: any) => {
  return mainApi
    .post(`/payment-historys`, data)
    .then((results: AxiosResponse<any>) => results.data)
    .catch(console.error);
};

export const readPaymentHistory = async (params: any) => {
  return mainApi
    .get(`/payment-historys`, { params: params })
    .then((results: AxiosResponse<any>) => results.data)
    .catch(console.error);
};

export const updatePaymentHistory = async (data: any) => {
  return mainApi
    .put(`/payment-historys`, data)
    .then((results: AxiosResponse<any>) => results.data)
    .catch(console.error);
};

export const deletePaymentHistory = async (params: any) => {
  return mainApi
    .delete(`/payment-historys`, { params: params })
    .then((results: AxiosResponse<any>) => results.data)
    .catch(console.error);
};
