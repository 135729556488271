import React, { useState } from "react";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  CssBaseline,
  MuiThemeProvider,
  createMuiTheme
} from "@material-ui/core";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import Login from "./containers/Login/Login";
import Home from "./containers/Home/Home";
import SubmitRequest from "./containers/SubmitRequest/SubmitRequest";
import MyRequests from "./containers/MyRequests/MyRequests";
import PaymentHistory from "./containers/PaymentHistory/PaymentHistory";
import Header from "./components/Header/Header";

const styles = (theme: Theme) => createStyles({});

const App = (props: any) => {
  const { classes } = props;

  const [links, setLinks] = useState([
    { name: "Submit Request", route: "/SubmitRequest" },
    { name: "My Requests", route: "/MyRequests" },
    { name: "Payment History", route: "/PaymentHistory" }
  ]);

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#293133",
        contrastText: "#ffffff"
      },
      secondary: {
        main: "#f3f3f3",
        contrastText: "#000000"
      },
      type: "dark"
    }
  });

  return (
    <Router>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Header links={links} />
        <Switch>
          {/* <Route
            exact
            path="/"
            render={(routeProps: any) => (
              <Login {...routeProps} links={links} />
            )}
          />
          <ProtectedRoute
            exact
            path="/Home"
            allow={["Admin", "User"]}
            component={(routeProps: any) => (
              <Home {...routeProps} links={links} />
            )}
          />
          <ProtectedRoute
            exact
            path="/Home/:id"
            allow={["Admin", "User"]}
            component={(routeProps: any) => (
              <Home {...routeProps} links={links} />
            )}
          />
          <ProtectedRoute
            exact
            path="/SubmitRequest"
            allow={["Admin", "User"]}
            component={(routeProps: any) => (
              <SubmitRequest {...routeProps} links={links} />
            )}
          />
          <ProtectedRoute
            exact
            path="/SubmitRequest/:id"
            allow={["Admin", "User"]}
            component={(routeProps: any) => (
              <SubmitRequest {...routeProps} links={links} />
            )}
          />
          <ProtectedRoute
            exact
            path="/MyRequests"
            allow={["Admin", "User"]}
            component={(routeProps: any) => (
              <MyRequests {...routeProps} links={links} />
            )}
          />
          <ProtectedRoute
            exact
            path="/MyRequests/:id"
            allow={["Admin", "User"]}
            component={(routeProps: any) => (
              <MyRequests {...routeProps} links={links} />
            )}
          />
          <ProtectedRoute
            exact
            path="/PaymentHistory"
            allow={["Admin", "User"]}
            component={(routeProps: any) => (
              <PaymentHistory {...routeProps} links={links} />
            )}
          />
          <ProtectedRoute
            exact
            path="/PaymentHistory/:id"
            allow={["Admin", "User"]}
            component={(routeProps: any) => (
              <PaymentHistory {...routeProps} links={links} />
            )}
          /> */}
          <Route
            exact
            path="/MyRequests"
            render={(routeProps: any) => (
              <MyRequests {...routeProps} links={links} />
            )}
          />
          <Route
            exact
            path="/"
            render={(routeProps: any) => (
              <PaymentHistory {...routeProps} links={links} />
            )}
          />
          <Route
            exact
            path="/SubmitRequest"
            render={(routeProps: any) => (
              <SubmitRequest {...routeProps} links={links} />
            )}
          />
        </Switch>
      </MuiThemeProvider>
    </Router>
  );
};

export default withStyles(styles)(App);
