import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Button, useMediaQuery } from "@material-ui/core";
import MaterialTable from "../../components/MaterialTable/MaterialTable";

import {
  createPaymentHistory,
  updatePaymentHistory,
  readPaymentHistory
} from "../../services/PaymentHistory/PaymentHistory.service";

interface Values {
  date: string;
  amount: number;
}

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    margin: 32
  },
  headerMobile: {
    fontSize: "12vw",
    marginBottom: 16,
    fontEeight: 100
  },
  table: {
    minWidth: 650
  },
  button: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    marginBottom: 16
  },
  innerButton: {
    marginRight: 16
  }
});

const AppList = (props: any) => {
  const classes = useStyles();
  const { history } = props;
  const mobileScreen = useMediaQuery("(max-width:600px)");

  const addItemLabel = "Submit Payment";
  const listTitle = "Payments";
  const tableColumns: any[] = [
    { label: "Date", value: "date" },
    { label: "Amount", value: "amount" }
  ];

  const [list, setList] = useState([] as Values[]);

  useEffect(() => {
    getListData();
  }, []);

  const addItem = (values: any) => {
    createPaymentHistory(values).then((response: any) => {
      if (response) {
      }
    });
  };

  const getListData = () => {
    readPaymentHistory({}).then((response: any) => {
      if (response) {
        setList(response);
      } else {
        setList([]);
      }
    });
  };

  const rowAction = (values: any) => {
    updatePaymentHistory(values).then((response: any) => {
      if (response) {
      }
    });
  };

  return (
    <div className={classes.root}>
      <Typography
        variant="h2"
        className={mobileScreen ? classes.headerMobile : ""}
      >
        {listTitle}
      </Typography>
      <div className={classes.button}>
        <Button variant="contained" color="primary" onClick={addItem} className={classes.innerButton}>
          Setup Autopay
        </Button>
        <Button variant="contained" color="default" onClick={addItem}>
          {addItemLabel}
        </Button>
      </div>
      <MaterialTable
        tableColumns={tableColumns}
        list={list}
        rowAction={rowAction}
      />
    </div>
  );
};

export default AppList;
