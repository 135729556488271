import React from "react";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Formik, Form, Field, ErrorMessage } from "formik";

import {
  Button,
  LinearProgress,
  Paper,
  FormControl,
  InputLabel,
  MenuItem,
  Typography
} from "@material-ui/core";
import {
  TextField,
  Select,
} from "formik-material-ui";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import {
  createSubmitRequest
} from "../../services/SubmitRequest/SubmitRequest.service";

interface Values {
  subject: string;
  message: string;
  category: string;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
      minHeight: "calc(100vh - 64px)",
      flexDirection: "column"
    },
    paper: {
      padding: theme.spacing(4),
      display: "flex",
      flexDirection: "column",
      minWidth: "40vw",
      alignItems: "center"
    },
    control: {
      margin: theme.spacing(2),
      width: "calc(100% - 32px)"
    },
    formControl: {
      width: "100%"
    }
  });

const AppForm = (props: any) => {
  const { classes, history } = props;
  const mobileScreen = useMediaQuery("(max-width:600px)");

  let initialValues: Values = {
    subject: "",
    message: "",
    category: ""
  };

  const submit = (values: any, { setSubmitting }: any) => {
    setSubmitting(false);
    createSubmitRequest(values).then((response: any) => {
      if (response) {
        history.push("Home");
      }
    });
  };

  return (
    <div className={classes.root}>
      <Typography
        variant="h2"
        className={mobileScreen ? classes.headerMobile : ""}
      >
        Submit Request
      </Typography>
      <Paper className={classes.paper}>
        <Formik
          initialValues={initialValues}
          validate={values => {
            const errors: Partial<Values> = {};

            return errors;
          }}
          onSubmit={submit}
        >
          {({
            submitForm,
            isSubmitting
          }) => (
            <Form>
              <Field
                className={classes.control}
                component={TextField}
                color="secondary"
                label="Subject"
                name="subject"
                variant="outlined"
              />
              <ErrorMessage name="Subject" />

              <Field
                className={classes.control}
                component={TextField}
                color="secondary"
                label="Message"
                name="message"
                variant="outlined"
              />
              <ErrorMessage name="Message" />

              <div className={classes.control}>
                <FormControl className={classes.formControl} color="secondary">
                  <InputLabel id="category-label">Category</InputLabel>
                  <Field
                    component={Select}
                    name="category"
                    inputProps={{
                      id: "category-label"
                    }}
                  >
                    <MenuItem value="PLUMBING">Plumbing</MenuItem>
                    <MenuItem value="ELECTRICAL">Electrical</MenuItem>
                    <MenuItem value="GAS">Gas</MenuItem>
                    <MenuItem value="LARGEAPPLIANCE">Large Appliance</MenuItem>
                    <MenuItem value="OTHER">Other</MenuItem>
                  </Field>
                </FormControl>
              </div>
              <ErrorMessage name="Category" />

              {isSubmitting && <LinearProgress />}
              <Button
                className={classes.control}
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                onClick={submitForm}
              >
                Submit
              </Button>
            </Form>
          )}
        </Formik>
      </Paper>
    </div>
  );
};

export default withStyles(styles)(AppForm);
