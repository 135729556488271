import React from "react";
import { withRouter } from "react-router";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import MoreIcon from "@material-ui/icons/More";
import AccountCircle from "@material-ui/icons/AccountCircle";
import GroupWorkIcon from "@material-ui/icons/GroupWork";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logo: {
      marginRight: theme.spacing(2)
    },
    title: {
      flexGrow: 1
    },
    titleCenter: {
      flexGrow: 1,
      display: "flex",
      justifyContent: "center"
    },
    smallLogo: {
      height: 32,
      width: 32
    },
    largeLogo: {
      height: 32,
      width: 64
    },
    highlight: {
      color: "#005EFF",
      fontWeight: "bold"
    }
  })
);

const Header = (props: any) => {
  const { auth, history } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const goToLink = (link: string) => {
    handleClose();
    history.push(link);
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" className={classes.titleCenter}>
          Continental <span className={classes.highlight}>&nbsp;1214&nbsp;</span> Portal
        </Typography>
        <div>
          <IconButton
            aria-label="application menu"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            open={open}
            onClose={handleClose}
          >
            <MenuItem onClick={() => goToLink("/")}>Payments</MenuItem>
            <MenuItem onClick={() => goToLink("/MyRequests")}>My Requests</MenuItem>
            <MenuItem onClick={() => goToLink("/SubmitRequest")}>Submit Request</MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default withRouter(Header);
