import { API_AXIOS_CONFIG } from "../constants/constants";
import Axios from "axios";

const mainApi = Axios.create(API_AXIOS_CONFIG);
mainApi.interceptors.request.use((config: any) => {
	const token = sessionStorage.getItem("accessToken");
	config.headers.Authorization = `Bearer ${token}`;

	return config;
}, ((error: any) => console.log(error)));
export default mainApi;