import { AxiosResponse } from "axios";
import mainApi from "../../config/mainApi";

export const createSubmitRequest = async (data: any) => {
  return mainApi
    .post(`/submit-requests`, data)
    .then((results: AxiosResponse<any>) => results.data)
    .catch(console.error);
};

export const readSubmitRequest = async (params: any) => {
  return mainApi
    .get(`/submit-requests`, { params: params })
    .then((results: AxiosResponse<any>) => results.data)
    .catch(console.error);
};

export const updateSubmitRequest = async (data: any) => {
  return mainApi
    .put(`/submit-requests`, data)
    .then((results: AxiosResponse<any>) => results.data)
    .catch(console.error);
};

export const deleteSubmitRequest = async (params: any) => {
  return mainApi
    .delete(`/submit-requests`, { params: params })
    .then((results: AxiosResponse<any>) => results.data)
    .catch(console.error);
};
