import React from 'react';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import { TableContainer, Table, TableHead, TableRow, Paper, TableCell, TableBody, Button, Box, Card, Typography, Grid } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const styles = (theme: Theme) => createStyles({
  table: {
    minWidth: 550
  },
  card: {
    padding: theme.spacing(1)
  },
  cardRow: {
    display: "flex",
    justifyContent: "space-between"
  },
  heading: {
    fontSize: "0.9rem",
    fontWeight: 500,
    lineHeight: 1.7
  },
  value: {
    fontSize: "0.8rem",
    lineHeight: 1.7
  }
});

const MaterialTable = (props: any) => {
  const { classes, tableColumns, list, rowAction } = props;
  const mobileScreen = useMediaQuery('(max-width:600px)');

  const transformBool = (value: boolean) => {
    if (value) return "Yes";
    return "No" 
  }
  
  return (
    <>
    {
      !mobileScreen ? 
      <TableContainer component={Paper}>  
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              {tableColumns.map((column: any, index: number) =>
                index === 0 ? (
                  <TableCell key={index}>{column.label}</TableCell>
                ) : (
                  <TableCell key={index} align="right">
                    {column.label}
                  </TableCell>
                )
              )}
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((row: any, index: number) => (
              <TableRow key={index}>
                {tableColumns.map((column: any, i: number) =>
                  i === 0 ? (
                    <TableCell key={i} component="th" scope="row">
                      {row[column.value]}
                    </TableCell>
                  ) : (
                    <TableCell key={i} align="right">
                      {typeof row[column.value] === "boolean" ? transformBool(row[column.value]) : row[column.value]}
                    </TableCell>
                  )
                )}
                <TableCell align="right">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => rowAction(row)}
                  >
                    Row Action
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      :
      <Grid container spacing={2}>
        {list.map((row: any, index: number) => (
          <Grid item xs={12}>
            <Card key={index} className={classes.card} variant="outlined">
              {tableColumns.map((column: any, i: number) => (
                <div className={classes.cardRow}>
                  <Typography variant="body1" className={classes.heading} component="span">
                    {column.label}
                  </Typography>
                  <Typography variant="caption" className={classes.value} component="span">
                    {typeof row[column.value] === "boolean" ? transformBool(row[column.value]) : row[column.value]}
                  </Typography>
                </div>
              ))}
            </Card>
          </Grid>
        ))}
      </Grid>
    }
    </>
  );
}

export default withStyles(styles)(MaterialTable);