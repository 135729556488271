import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Button, useMediaQuery } from "@material-ui/core";
import MaterialTable from "../../components/MaterialTable/MaterialTable";
import { createSubmitRequest, updateSubmitRequest, readSubmitRequest } from "../../services/SubmitRequest/SubmitRequest.service";

interface Values {
  subject: string;
  message: string;
  category: string;
}

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    margin: 32
  },
  headerMobile: {
    fontSize: "12vw",
    marginBottom: 16,
    fontWeight: 100
  },
  table: {
    minWidth: 650
  },
  button: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    marginBottom: 16
  }
});

const AppList = (props: any) => {
  const classes = useStyles();
  const { history, location } = props;
  const mobileScreen = useMediaQuery("(max-width:600px)");

  const addItemLabel = "Submit Request";
  const listTitle = "My Requests";
  const tableColumns: any[] = [
    { label: "Subject", value: "subject" },
    { label: "Message", value: "message" },
    { label: "Category", value: "category" }
  ];

  const [list, setList] = useState([
    { subject: "", message: "", category: "" }
  ] as Values[]);

  useEffect(() => {
    getListData();
  }, []);

  const addItem = () => {
    history.push("/SubmitRequest")
  };

  const getListData = () => {
    readSubmitRequest({}).then((response: any) => {
      if (response) {
        setList(response);
      } else {
        setList([]);
      }
    });
  };

  const rowAction = (values: any) => {
    updateSubmitRequest(values).then((response: any) => {
      if (response) {
      }
    });
  };

  return (
    <div className={classes.root}>
      <Typography
        variant="h2"
        className={mobileScreen ? classes.headerMobile : ""}
      >
        {listTitle}
      </Typography>
      <div className={classes.button}>
        <Button variant="contained" color="default" onClick={addItem}>
          {addItemLabel}
        </Button>
      </div>
      <MaterialTable
        tableColumns={tableColumns}
        list={list}
        rowAction={rowAction}
      />
    </div>
  );
};

export default AppList;
